<template>

    <v-col v-if="free" class="ma-0 pa-0">
    <v-hover v-slot="{ hover }" style="cursor:pointer;">
      <v-card 
        justify-center
        elevation=0
        class=" my-0 pa-0 "
      >
  
    <div class="d-flex align-end justify-end" style="position:absolute ; height:100%; z-index:90; width:100%;" @click="showContent">
      <v-btn
        elevation=0
        text
        dense 
        small
        @click="showContent"
        color="white"
        :class="(hover & $vuetify.breakpoint.smAndUp) ? 'ma-1 px-1 on-hover-img rounded-sm  play-btn ':'on-hover-img' " >
        クリックして表示
      </v-btn>
    </div>
    <v-img  
      :src=" type=='sound' ? soundImg : status ? imgItem.thumbnail : altImg " 
      :lazy-src="altImg"
      :alt="imgItem.name"
      v-on:error="status=false" 
      :aspect-ratop="16/9"  
    ></v-img>

      </v-card>
    </v-hover>
  </v-col>

  <div v-else-if="type=='sound'" width="50" class="text-center">
    <v-icon large v-if="!isPlaying" @click="showContent"> {{icons.arrowRightDropCircleOutline}} </v-icon>
    <v-icon large color="narekomu" v-else @click="stopSound"> {{icons.pauseCircleOutline}} </v-icon>
  </div>

  <div v-else-if="isWaypoint" width="150" style="margin:0;">
    <v-img  
      :src="imgItem.thumbnail" 
      :alt="imgItem.name  " 
      :aspect-ratop="16/9" 
      :height="100" 
      :width="150"  
    ></v-img>
  </div>


  <div v-else >
    <v-hover v-slot="{ hover }" style="cursor:pointer;">
      <v-card 
        justify-center
        class=" my-1 rounded-sm"
      >
  
        <div v-if="!isTop" class="d-flex align-end justify-end" style="position:absolute ; height:100%; z-index:90; width:100%;" @click="showContent">
          <v-btn
            elevation=0
            text
            dense 
            small
            @click="showContent"
            color="white"
            :class="(hover & $vuetify.breakpoint.smAndUp) ? 'ma-1 px-1 on-hover-img rounded-sm  play-btn ':'on-hover-img' " >
            クリックして表示
          </v-btn>
        </div>
    

        <!-- Youtube like -->
        <v-img  
          :src=" status ? imgItem.thumbnail : altImg " 
          :lazy-src="altImg"
          :alt="imgItem.name  " 
          aspect-ratop=16/9 
          v-on:error="status=false"  
          width="10vw" 
          height="9vw"
          min-width="160px" 
          max-width="150px"
          min-height="90px"
          max-height="80px"
          class="ma-0 pa-0"
          @click="showContent"
        >
          <template v-slot:placeholder v-if="!status">
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>

        </v-img>

      </v-card>

    </v-hover>

  </div>

</template> 

<script>
import { mdiArrowRightDropCircleOutline, mdiPauseCircleOutline } from '@mdi/js';

export default {
  name: 'TableThumbnail',
  props:['item','change','isWaypoint',"idName","type","soundId","isTop","free"],
  data () {
    return {
      icons:{
        "pauseCircleOutline":mdiPauseCircleOutline,
        "arrowRightDropCircleOutline":mdiArrowRightDropCircleOutline
      },
      status:true,
      dialog:false,
      dialogRestore:false,
      imgItem:{},
      onced:false,
      isPlaying:false,
      altImg:require("@/assets/navi.jpg"),
      soundImg:require("@/assets/sound.webp"),
    }
  },
  watch : {
    change: function(){
      this.imgItem = this.item;
      this.status = true;

    },

    soundId: function(){
      this.isPlaying = (this.soundId == this.item.soundId);
    }
  },
  methods : {

    limitText(text){
      if(text.length < 18) return text;
      else return text.substr(0,18) + "..."
    },

    imgReload(){
      this.imgItem = this.item;
      this.status = true;
    },

    changeThumbnail(){
      this.$emit('changeThumbnailEmit',this.item)
    },
    
    save(img){
      this.$emit('saveEmit',img,this.item)
      this.dialog = false;
      this.dialogRestore = false;
    },

    restoreThumbnail(){
      this.$emit('restoreThumbnailEmit',this.item)
    },

    close(){
      this.dialog = false;
      this.dialogRestore = false;
    },

    //コンテンツの再生、表示
    showContent(){
      this.$emit('showContentEmit',this.item,true);
    },

    //音声の再生停止
    stopSound(){       
       this.$emit('stopSoundEmit',this.item,true)
    },
    

  }
}
</script>


<style scoped>


.on-hover-img {
  transition: opacity .1s ease-in-out;
  opacity: 0;
 }

 .v-overlay >>> .v-overlay__content {
    width: 100%;
    height: 100%;
  }

.menu-color{
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.265) !important;
}

.play-btn{
  opacity: 1;
  background: rgba(14, 14, 14, 0.394);
}

.content-name{
  opacity: 1;
  background: rgb(255, 255, 255);
}


</style>
